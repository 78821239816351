.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.title {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.pin-item-container {
  display: flex;
  gap: 12px;
  cursor: text;
  outline: none;
}

.pin-item {
  width: 40px;
  height: 56px;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #121212;
  border-radius: 50%;
}

@keyframes blink {
  0% {
    border-bottom-color: #343434;
  }
  40% {
    border-bottom-color: transparent;
  }
  100% {
    border-bottom-color: #343434;
  }
}

.previous-pin {
  border-bottom: 1px solid #909098;
}

.current-pin {
  border-bottom: 2px solid #343434;
}

.blinking-pin {
  border-bottom: 3px solid #343434;
  animation: blink 0.5s linear infinite;
}

.next-pin {
  border-bottom: 1px solid #bbbbc0;
}

.underline-medium {
  text-decoration: underline;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.bottom-control-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  bottom: 0;
  padding: 20px;
  border-top: 1px solid #e6e6e8;
  width: 100%
}

/* .button {
  margin: 0 40px;
} */

.bottom-control-container_on-page {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% + 140px);
  margin-top: 32px;
}

.button_on-page {
  width: 100%;
}

.success-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.error-pin {
  border-bottom: 1px solid #E73126 !important;
}

.error-text {
  margin-top: 8px;
  text-align: center;
  color: #E73126 !important;
}

.pin-input {
  position: absolute;
  background-color: transparent;
  /* width: 300px; */
  height: 56px;
  opacity: 0;
}